* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html, body, #app {
  min-width: 100%;
  min-height: 100vh;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
}

#app {
  display: flex;
}
