.invoices-block {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 36px 5px #ebe9f1;
  overflow-x: auto;
}
.invoices-block__main {
  min-width: 860px;
}
.invoices-block__placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 128px;
}
.invoices-block__price {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.invoices-block__price b {
  margin-right: 4px;
    }
