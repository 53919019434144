.cabinet-header-block {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  line-height: 1;
}
.cabinet-header-block__company-label {
  font-weight: 600;
  text-transform: uppercase;
}
.cabinet-header-block__user {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: auto;
}
.cabinet-header-block__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  margin-left: 8px;
  border: #f5f5f5 1px solid;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 650px) {
  .cabinet-header-block__logo {
  display: none;
  }
    }
.cabinet-header-block__name {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: space-between;
  text-align: right;
}
.cabinet-header-block__exit {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 32px;
  font-size: 12px;
}
.cabinet-header-block__icon {
  margin: 0 0 -1px 4px;
}
