.balance-block {
  overflow-x: auto;
}
.balance-block__main {
  min-width: 860px;
}
.balance-block__link {
  text-decoration: none;
  text-align: right;
  color: #3f62b0;
  font-size: 24px;
}
.balance-block__amount {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}
.balance-block__amount-value {
  margin-right: 16px;
  font-size: 22px;
  font-weight: 500;
}
.balance-block__amount-button {
  display: block;
  padding: 16px 24px;
  border: none;
  border-radius: 32px;
  background-color: #eb4752;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-decoration: none;
}
.balance-block__amount-button:hover {
  background-color: #de434e;
    }
.balance-block__amount-bill {
  text-align: center;
}
.balance-block__installment {
  all: unset;
  display: block;
  margin-top: 12px;
  background-color: transparent;
  border: none;
  color: #eb4752;
  font-size: 12px;
}
.balance-block__installment:hover {
  text-decoration: underline;
    }
.balance-block__balance {}
.balance-block__balance_color_green {
  color: #417505;
}
.balance-block__balance_color_red {
  color: #9d0d1f;
}
.balance-block__placeholder {
  position: relative;
  min-height: 32px;
}
