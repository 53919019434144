.cabinet-main-page {}
.cabinet-main-page__row {
  margin-bottom: 32px;
}
.cabinet-main-page__row:last-child {
  margin-bottom: 0;
    }
.cabinet-main-page__row_zero {
  margin-bottom: 0;
}
