.tariffs-list-component {
  width: 100%;
  margin-top: 16px;
}
.tariffs-list-component__header {
  margin-bottom: 16px;
}
.tariffs-list-component__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 -32px;
  list-style: none;
}
@media (max-width: 600px) {
  .tariffs-list-component__list {
  margin: 0 -16px;
  }
    }
.tariffs-list-component__item {
  flex: 1 1 calc(50% - 64px);
  margin: 0 32px 32px;
}
@media (max-width: 600px) {
  .tariffs-list-component__item {
  margin: 0 16px 16px;
  }
    }
