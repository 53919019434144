.balance-banner-component {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  color: #fff;
  background-image: linear-gradient(to right, #f9b48a, #d86dbc, #4968df);
  border-radius: 16px;
  box-shadow: 0 2px 36px 5px #ebe9f1;
}

  @media (max-width: 900px) {

  .balance-banner-component {
  flex-flow: column;
  justify-content: center;
  }
  }

.balance-banner-component__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 24px;
  font-weight: 300;
}

@media (max-width: 900px) {

  .balance-banner-component__title {
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
  }
    }

.balance-banner-component__icon {
  font-size: 48px;
  margin-right: 16px;
}

.balance-banner-component__payment {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 32px;
}

@media (max-width: 500px) {

  .balance-banner-component__payment {
  flex-flow: column;
  }
    }

.balance-banner-component__button {
  margin-left: 16px;
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

@media (max-width: 500px) {

  .balance-banner-component__button {
  margin: 16px 0 0 0;
  }
    }

.balance-banner-component__button:hover {
  background-color: rgba(0, 0, 0, 0.4);
    }

.balance-banner-component__button[disabled] {
  color: rgba(255, 255, 255, 0.3);
  pointer-events: none;
    }

.balance-banner-component__border {
  flex: 0 0 1px;
  height: 96px;
  background-color: rgba(255, 255, 255, 0.3);
}

@media (max-width: 900px) {

  .balance-banner-component__border {
  display: none;
  }
    }

.balance-banner-component__box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  min-height: 96px;
}

@media (max-width: 900px) {

  .balance-banner-component__box {
  width: 100%;
  min-height: auto;
  margin-bottom: 8px;
  }
    }

.balance-banner-component__description {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 300;
}

.balance-banner-component__content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.balance-banner-component__amount {
  font-size: 32px;
}
