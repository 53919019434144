.cabinet-footer-block {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  padding: 32px;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 32px 5px #ebe9f1;
  background-image: linear-gradient(90deg, #8acef9, #6dd8ca, #4980df);
  font-size: 24px;
}

  @media (max-width: 860px) {

  .cabinet-footer-block {
  flex-flow: column nowrap;
  align-items: center;
  padding: 16px;
  }
  }

.cabinet-footer-block__img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}

.cabinet-footer-block__title {
  margin-left: 32px;
  font-weight: 300;
  text-align: center;
}

@media (max-width: 860px) {

  .cabinet-footer-block__title {
  margin-left: 0;
  }
    }

.cabinet-footer-block__phone {
  margin-left: auto;
  font-size: 42px;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 860px) {

  .cabinet-footer-block__phone {
  margin-left: initial;
  }
    }
