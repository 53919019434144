.tariff-component {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  min-width: 400px;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  color: #333;
  transition: transform 0.1s linear;
}

  .tariff-component:hover {
  transform: scale(1.03);
  }

  @media (max-width: 400px) {

  .tariff-component {
  min-width: auto;
  }
  }

.tariff-component__main {
  flex: 1 1 auto;
  padding: 16px 16px 0 16px;
}

.tariff-component__footer {
  margin: 8px 0 16px 0;
}

.tariff-component__icon {
  font-size: 64px;
}

.tariff-component__name {
  font-size: 24px;
  font-weight: 700;
}

.tariff-component__description {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  font-weight: 300;
}

.tariff-component__props {
  max-width: 480px;
  margin: 32px auto 8px auto;
  padding: 8px 16px;
  list-style: none;
  background-color: rgba(192, 211, 254, 0.1);
  border: solid 1px rgba(192, 211, 254, 0.2);
  border-radius: 8px;
}

.tariff-component__props-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 300;
}

.tariff-component__prop-name {
  flex: 0 1 120px;
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
}

.tariff-component__prop-description {
  flex: 1 1 auto;
  text-align: left;
  color: #0058c0;
}

.tariff-component__fixed {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-weight: 300;
}

.tariff-component__fixed-marker {
  color: #d80808;
}

.tariff-component__button {
  padding: 8px 16px;
  color: #fff;
  background-color: #aa3a91;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
