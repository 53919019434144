.price-chart-block {
  position: relative;
  padding: 16px;
  border: #d4e5f9 1px solid;
  border-radius: 8px;
  box-shadow: 0 2px 36px 5px #ebe9f1;
}
.price-chart-block__header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 12px;
}
.price-chart-block__date {
  color: #929292;
}
