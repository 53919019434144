.cabinet-layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
}
.cabinet-layout__header {
  flex: 0 0 60px;
  width: 100%;
  padding: 0 32px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
}
@media (max-width: 600px) {
  .cabinet-layout__header {
  padding: 0 16px;
  }
    }
.cabinet-layout__logo {
  height: 36px;
}
.cabinet-layout__header-content {
  max-width: 1100px;
  height: 100%;
  margin: 0 auto;
}
.cabinet-layout__sub {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0;
}
@media (max-width: 500px) {
  .cabinet-layout__sub {
  flex-flow: column nowrap;
  }
    }
.cabinet-layout__switch {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: auto;
  color: #a5a6af;
  text-decoration: none;
}
@media (max-width: 500px) {
  .cabinet-layout__switch {
  margin: 0;
  }
    }
.cabinet-layout__switch:hover {
  color: #4221e3;
    }
.cabinet-layout__switch-icon {
  margin-left: 8px;
}
.cabinet-layout__main {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  max-width: 1100px;
  width: calc(100% - 64px);
  margin: 0 32px;
}
@media (max-width: 600px) {
  .cabinet-layout__main {
  margin: 0 16px;
  width: calc(100% - 32px);
  }
    }
.cabinet-layout__content {
  flex: 1 1 auto;
}
.cabinet-layout__footer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0;
}
@media (max-width: 1000px) {
  .cabinet-layout__footer {
  flex-flow: column nowrap;
  }
    }
