.invoice-controls-component {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.invoice-controls-component__download {
  background-color: transparent;
  border: none;
  color: #0852f9;
  font-size: 18px;
  cursor: pointer;
}
.invoice-controls-component__pay {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 32px;
  border: none;
  color: #fff;
  background-color: #0852f9;
  cursor: pointer;
  transition: background-color 0.1s ease;
}
.invoice-controls-component__pay:hover {
  background-color: #1e61f6;
    }
