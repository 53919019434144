.payment-success-page {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: 32px;
}
.payment-success-page__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.payment-success-page__main {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: auto;
  text-align: center;
}
.payment-success-page__image {
  width: 100%;
  margin-top: 32px;
}
