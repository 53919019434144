@keyframes join-handset {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(-32px);
  }

  40% {
    transform: translateY(-32px) rotate(-5deg);
  }

  45% {
    transform: translateY(-32px) rotate(5deg);
  }

  50% {
    transform: translateY(-32px) rotate(-5deg);
  }

  55% {
    transform: translateY(-32px) rotate(5deg);
  }

  60% {
    transform: translateY(-32px) rotate(-5deg);
  }

  65% {
    transform: translateY(-32px) rotate(5deg);
  }

  70% {
    transform: translateY(-32px) rotate(-5deg);
  }

  75% {
    transform: translateY(-32px) rotate(5deg);
  }

  80% {
    transform: translateY(-32px) rotate(-5deg);
  }

  85% {
    transform: translateY(-32px) rotate(5deg);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
}
.join-page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 32px auto 32px;
}
.join-page__header {
  flex: 0 0 60px;
  margin: 0 64px;
}
@media (max-width: 520px) {
  .join-page__header {
  margin: 0 32px;
  }
    }
.join-page__main {
  flex: 1 1 auto;
  display: flex;
  margin: 0 64px;
}
@media (max-width: 1000px) {
  .join-page__main {
  margin-right: 0;
  }
    }
@media (max-width: 520px) {
  .join-page__main {
  margin: 0 32px;
  }
    }
.join-page__center {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1000px) {
  .join-page__center {
  width: 100%;
  }
    }
.join-page__content {
  flex: 1 1 auto;
  margin-right: 64px;
  min-width: 400px;
}
@media (max-width: 520px) {
  .join-page__content {
  width: 100%;
  min-width: auto;
  margin: 0;
  }
    }
.join-page__title {
  margin: 0 0 16px 0;
  font-size: 64px;
  line-height: 1;
}
.join-page__title::after {
  content: "";
  display: block;
  width: 180px;
  margin-top: 16px;
  height: 4px;
  background-color: #0e50c8;
    }
.join-page__text {
  max-width: 450px;
  margin-top: 32px;
  font-size: 18px;
  font-weight: 300;
  color: #909090;
  line-height: 24px;
}
.join-page__form {
  max-width: 300px;
  margin-top: 32px;
}
@media (max-width: 520px) {
  .join-page__form {
  max-width: inherit;
  width: 100%;
  }
    }
.join-page__img {
  max-width: 100%;
}
@media (max-width: 1000px) {
  .join-page__img {
  display: none;
  }
    }
.join-page__picture {
  position: relative;
}
.join-page__picture::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  display: block;
  width: 50%;
  height: 100%;
  background-image: url("/assets/images/join-phone-body.png");
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
    }
.join-page__picture::after {
  content: "";
  position: absolute;
  top: 128px;
  left: 15%;
  display: block;
  width: 70%;
  height: 100%;
  background-image: url("/assets/images/join-phone-handset.png");
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
    }
@media (max-width: 1000px) {

      .join-page__picture {
  width: 300px;
  height: 100%;
  background-image: url("/assets/images/join-page-bg.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  }

      .join-page__picture::before, .join-page__picture::after {
  display: none;
      }
    }
@media (max-width: 520px) {
  .join-page__picture {
  display: none;
  }
    }
.join-page__picture_animate::after {
  animation: join-handset 2s ease-in-out;
  animation-delay: 1s;
  will-change: transform;
      }
