.news-gallery-block {
  position: relative;
  height: 380px;
  border-radius: 16px;
  box-shadow: 0 2px 36px 5px #ebe9f1;
  overflow: hidden;
}
.news-gallery-block__news {
  height: 100%;
  list-style: none;
}
.news-gallery-block__news-item {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: transform 0.5s ease;
  will-change: transform;
  cursor: pointer;
}
.news-gallery-block__news-item_active {
  transform: translateX(0);
  z-index: 1;
}
.news-gallery-block__news-item_active ~ .news-gallery-block__news-item {
  transform: translateX(100%);
      }
.news-gallery-block__news-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.news-gallery-block__news-image::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #7aa8fc, #2a0745);
  opacity: 0.5;
    }
.news-gallery-block__news-data {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 32px;
  background-color: rgba(12, 8, 59, 0.6);
  border-radius: 0 16px 0 0;
  color: #fff;
  font-weight: 300;
  z-index: 1;
}
.news-gallery-block__news-title {
  font-size: 24px;
}
.news-gallery-block__markers {
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  z-index: 1;
}
.news-gallery-block__markers-item {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border: #fff 1px solid;
  border-radius: 50%;
  cursor: pointer;
}
.news-gallery-block__markers-item:last-child {
  margin-right: 0;
    }
.news-gallery-block__markers-item_active {
  background-color: #fff;
}
.news-gallery-block__control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 64px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}
.news-gallery-block__control_disabled {
  pointer-events: none;
  opacity: 0.4;
}
.news-gallery-block__control_prev {
  left: 0;
}
.news-gallery-block__control_next {
  right: 0;
}
