.installments-block {
  width: 700px;
}
.installments-block__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.installments-block__bank {
  flex: 0 0 20%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 500;
}
.installments-block__icon {
  max-width: 32px;
  margin-right: 12px;
}
.installments-block__main {
  margin: 32px 0;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  color: #234CAC;
}
.installments-block__form {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.installments-block__button {
  flex: 0 0 100px;
}
