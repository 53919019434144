.tariffs-block {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 32px;
  padding: 32px;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  color: #fff;
  background-image: url("/assets/images/banner-bg.png"),
    linear-gradient(to right, #9c21e3, #db540b);
}
.tariffs-block__header {
  margin-bottom: 32px;
  text-align: center;
}
.tariffs-block__objects {
  list-style: none;
}
.tariffs-block__object-item {
  margin-bottom: 16px;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  line-height: 18px;
}
.tariffs-block__choose-button {
  margin-left: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
}
.tariffs-block__choose {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
}
.tariffs-block__tariffs {
  display: none;
  width: 100%;
  margin-bottom: -24px;
}
.tariffs-block__tariffs_open {
  display: block;
}
.tariffs-block__account {
  margin-top: 16px;
}
